import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CRestaurantPlan,
  CSectTitle03,
  CSectTitle04,
  CTable,
  CTable02,
  CAnchorNav02,
  CGeneralCard,
  LOtherOiwai,
  LContact03,
  LContact,
} from '../../../components/_index';
import { restaurantPlanEventChoice } from '../../../utils/restaurantPlan-choice';
import restaurantPlanGet from '../../../utils/restaurantPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const eventclass = ['お宮参り'];

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: 'お宮参り',
            sub: <>CELEBRATION</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/oiwai/omiyamairi/kv.png',
              },
              imgSp: {
                src: '/assets/images/oiwai/omiyamairi/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="l_sect04 u_bgGray">
        <LWrap>
          <CSectTitle04 title={'お宮参り'} tag={'h2'} exClass="u_mb0" />
          <p className="u_tac_pc u_fs16 u_mb30">
            お子様の誕生と健やかな成長を願う慶祝のひとときをおもてなしいたします。
            <br />
            水天宮でのお宮参りの後は、記念撮影とレストランの個室でお食事をお楽しみください。
            <br />
            お子様もご家族も安心してゆったりとおくつろぎいただけます。
          </p>
          <CTable02
            exClass="u_bgWhite"
            data={[
              {
                title: '詳細',
                content: (
                  <>
                    <p>プランに含まれるもの</p>
                    <ul className="c_circleList u_mt10">
                      <li>お宮参り特別コース料理:大人4名様分</li>
                      <li>記念写真:六ツ切カラー 2ポーズ各1枚</li>
                    </ul>
                  </>
                ),
              },
              {
                title: '特典',
                content: (
                  <>
                    <ul className="c_circleList">
                      <li>個室料 無料</li>
                      <li>駐車場 最大7時間無料</li>
                      <li>クーハンをご用意</li>
                    </ul>
                  </>
                ),
              },
              {
                title: 'ご案内',
                content: (
                  <>
                    <ul className="c_circleList">
                      <li>
                        お子様の産着（祝い着）のお貸出しは、4F
                        衣裳室にて承っております。［予約制］
                      </li>
                    </ul>
                    <ul className="c_noteList u_mt10">
                      <li>別途料金が発生いたします。</li>
                    </ul>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </div>
      <section className="l_sect u_pt70">
        <LWrap>
          <CAnchorNav02
            exClass="u_mb0"
            data={[
              {
                title: (
                  <>
                    鉄板焼 <br className="u_sp" />
                    すみだ
                  </>
                ),
                link: {
                  href: '/oiwai/omiyamairi#sumida',
                },
              },
              {
                title: (
                  <>
                    日本料理 <br className="u_sp" />
                    源氏香
                  </>
                ),
                link: {
                  href: '/oiwai/omiyamairi#genjikoh',
                },
              },
              {
                title: (
                  <>
                    中国料理 <br className="u_sp" />
                    桂花苑
                  </>
                ),
                link: {
                  href: '/oiwai/omiyamairi#kei_ka_en',
                },
              },
            ]}
          />
          <div id="sumida" className="u_pt100">
            <CSectTitle03 title={'鉄板焼 すみだ'} exClass="u_mb40" />
            <CGeneralCard
              exClass="sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/oiwai/img_store.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/oiwai/img_store__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/restaurants/sumida/',
                  },
                  title: '鉄板焼 すみだ',
                  text: (
                    <>
                      ホテル最上階の景色とともに、目の前で焼き上げられていく和牛や魚介類をご堪能いただけます。
                      <br />
                      慶事のひとときを華やかにおもてなしする、ライブ感溢れる鉄板焼をお楽しみください。
                    </>
                  ),
                },
              ]}
            />
            <CRestaurantPlan
              data={restaurantPlanEventChoice(
                restaurantPlanGet(),
                ['鉄板焼 すみだ'],
                eventclass
              )}
              eventflag={true}
              buttonClass={'c_btn__bgWhite03'}
              exClass={'u_mb0'}
            />
          </div>
          <div id="genjikoh" className="u_pt100">
            <CSectTitle03 title={'日本料理 源氏香'} exClass="u_mb40" />
            <CGeneralCard
              exClass="sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/oiwai/img_store02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/oiwai/img_store02__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/restaurants/genjikoh/',
                  },
                  title: '日本料理 源氏香',
                  text: (
                    <>
                      落ち着きある雰囲気の中、日本庭園を眺めながら、ご家族と和やかに過ごせる風情ある空間。
                      <br />
                      お祝いの席に彩を添える、繊細な心遣いと四季折々の食材を生かした日本料理をご用意しております。
                    </>
                  ),
                },
              ]}
            />
            <CRestaurantPlan
              data={restaurantPlanEventChoice(
                restaurantPlanGet(),
                ['日本料理 源氏香'],
                eventclass
              )}
              eventflag={true}
              buttonClass={'c_btn__bgWhite03'}
              exClass={'u_mb0'}
            />
          </div>
          <div id="kei_ka_en" className="u_pt100">
            <CSectTitle03 title={'中国料理 桂花苑'} exClass="u_mb40" />
            <CGeneralCard
              exClass="sp_full"
              data={[
                {
                  img: {
                    src: '/assets/images/oiwai/img_store03.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/oiwai/img_store03__sp.png',
                    alt: '',
                  },
                  link: {
                    href: '/restaurants/kei_ka_en/',
                  },
                  title: '中国料理 桂花苑',
                  text: (
                    <>
                      ご家族で料理を囲み、喜びを分かち合うかけがえのないひととき。
                      <br />
                      ご多幸と健康への願いを込めて、お祝いの席にふさわしいバラエティあふれる広東料理の豊かな味わいをお楽しみください。
                    </>
                  ),
                },
              ]}
            />
            <CRestaurantPlan
              data={restaurantPlanEventChoice(
                restaurantPlanGet(),
                ['中国料理 桂花苑'],
                eventclass
              )}
              eventflag={true}
              buttonClass={'c_btn__bgWhite03'}
              exClass={'u_mb0'}
            />
          </div>
        </LWrap>
      </section>
      <LOtherOiwai exClass="u_pt0" />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'お祝い・記念日',
              path: '/oiwai/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact
        title={'ご予約・お問合せ'}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 10:00～17:30 ※水曜を除く）',
        }}
        linkList={[
          {
            label: 'よくあるご質問',
            link: {
              href: '/faq/',
            },
          },
          {
            label: '食物アレルギーをお持ちの方へ',
            link: {
              href: '/restaurants/allergy/',
            },
          },
        ]}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
